import React, { useState } from 'react';
import { useNotify, useRecordContext } from 'react-admin';
import { httpClient } from 'httpClient';

import { IceBreaker, IceBreakerModerationStatus } from 'model-types';
import ToggleActivateButton from 'layout/buttons/ToggleActivateButton';

export const IceBreakerBtns = () => {
  const record = useRecordContext<IceBreaker>();

  const notify = useNotify();

  const [activeBtn, setActiveBtn] = useState(record.is_active);
  const [loading, setLoading] = useState(false);

  const handleActivate = () => {
    setLoading(true);

    httpClient
      .post(`/ice-breaker/${record.id}/activate`)
      .then(() => setActiveBtn(1))
      .catch((err) => notify(err?.message))
      .finally(() => setLoading(false));
  };

  const handleDeactivate = () => {
    setLoading(true);

    httpClient
      .post(`/ice-breaker/${record.id}/deactivate`)
      .then(() => setActiveBtn(0))
      .catch((err) => notify(err?.message))
      .finally(() => setLoading(false));
  };

  if (
    !record ||
    record.moderation_status === IceBreakerModerationStatus.Moderation ||
    record.moderation_status === IceBreakerModerationStatus.Decline
  )
    return null;

  return (
    <ToggleActivateButton
      disabled={!!record.is_deleted || loading}
      isLoader={loading}
      isActivate={!activeBtn}
      handleActivate={handleActivate}
      handleDeactivate={handleDeactivate}
    />
  );
};
