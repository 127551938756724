import React from 'react';
import {
  Datagrid,
  DateField,
  List,
  NumberField,
  TextField,
  Filter,
  NumberInput,
  TextInput,
  BooleanField,
  ResourceComponentProps,
  SelectInput,
} from 'react-admin';

import IconField from 'layout/fields/IconField';
import BooleanFromValueField from 'layout/fields/BooleanFromValueField';
import { spendSegmentChoices, profileType } from '../field-choices';

const RegularUserFilter = (props: any) => (
  <Filter {...props} style={{ maxWidth: '1200px' }}>
    <NumberInput source="id" label="Id" alwaysOn style={{ width: '120px' }} />
    <TextInput
      source="email"
      alwaysOn
      label="Email"
      style={{ width: '120px' }}
    />
    <TextInput
      source="ulid_id"
      alwaysOn
      label="Ulid"
      style={{ width: '120px' }}
    />
    <TextInput
      source="username"
      alwaysOn
      label="Username"
      style={{ width: '120px' }}
    />
    <NumberInput
      source="site_id"
      label="Site"
      alwaysOn
      style={{ width: '120px' }}
    />
    <SelectInput
      source="profile_type"
      choices={profileType}
      alwaysOn
      style={{ width: '120px' }}
    />
    <SelectInput
      source="spend_segment"
      choices={spendSegmentChoices}
      alwaysOn
      style={{ width: '120px' }}
    />
  </Filter>
);

const RegularUserList = (props: ResourceComponentProps) => {
  return (
    <List
      {...props}
      filters={<RegularUserFilter />}
      perPage={100}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid optimized rowClick="edit">
        <NumberField source="id" />
        <TextField source="ulid_id" sortable={false} />
        <TextField source="email" />
        <NumberField source="site_id" sortable={false} />
        <TextField source="profile_type" sortable={false} />
        <NumberField source="age" sortable={false} />
        <TextField source="name" />
        <BooleanField source="is_deleted" sortable={false} />
        <TextField source="moderation_status" sortable={false} />
        <BooleanFromValueField source="is_test" />
        <BooleanField source="is_chb" sortable={false} />
        <NumberField source="credits" sortable={false} />
        <IconField source="thumbnail" label="Thumbnail" sortable={false} />
        <TextField source="photo_count" sortable={false} />
        <TextField source="city" sortable={false} />
        <TextField source="country" sortable={false} />
        <DateField
          source="created_at"
          locales="ru-RU"
          showTime
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};

export default RegularUserList;
