import React from 'react';
import { FC } from 'react';
import { required, TextInput } from 'react-admin';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  content: {
    width: '100%',
  },
}));

const requiredValidation = [required()];

export const GoogleAdsConfig: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <TextInput
            source="ga_measurement_id"
            className={classes.content}
            validate={requiredValidation}
          />
        </Grid>
        <Grid item xs>
          <TextInput
            source="ga_api_secret"
            className={classes.content}
            validate={requiredValidation}
          />
        </Grid>
      </Grid>
    </div>
  );
};
