import React from 'react';
import { FC } from 'react';
import {
  TextInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  required,
  AutocompleteInput,
} from 'react-admin';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { costModelChoices } from 'consts';
import {
  partnerGroupChoices,
  platformChoices,
  trafficTypeChoices,
} from './field-choices';

const requiredValidation = [required()];

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  content: {
    width: '100%',
  },
  document: {
    animationName: '$fade',
    '&:after': {
      content: '"!!! FUCK UK !!!"',
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%)',
      fontSize: '40px',
      zIndex: 1000,
    },
  },
  '@keyframes fade': {
    '0%': { opacity: '0' },
    '100%': { opacity: '1' },
  },
}));

export const MainConfigs: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextInput
            source="name"
            className={classes.content}
            validate={requiredValidation}
          />
        </Grid>
        <Grid item xs={2}>
          <ReferenceInput
            label="Network"
            source="network_id"
            reference="partners-networks"
            allowEmpty={true}
            filterToQuery={(searchText: any) => ({
              name: '%' + searchText + '%',
            })}
            validate={requiredValidation}
            alwaysOn
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
        </Grid>
        <Grid item xs={2}>
          <TextInput
            source="region"
            className={classes.content}
            validate={requiredValidation}
          />
        </Grid>
        <Grid item xs={2}>
          <TextInput
            source="country_code"
            className={classes.content}
            validate={requiredValidation}
          />
        </Grid>
        <Grid item xs={2}>
          <SelectInput
            source="traffic_type"
            className={classes.content}
            defaultValue={null}
            choices={trafficTypeChoices}
          />
        </Grid>
        <Grid item xs={2}>
          <SelectInput
            source="cost_model"
            className={classes.content}
            defaultValue={null}
            choices={costModelChoices}
          />
        </Grid>
        <Grid item xs={2}>
          <SelectInput
            source="platform"
            className={classes.content}
            defaultValue={null}
            choices={platformChoices}
          />
        </Grid>
        <Grid item xs={2}>
          <TextInput
            source="manager"
            className={classes.content}
            validate={requiredValidation}
          />
        </Grid>
        <Grid item xs={2}>
          <TextInput
            source="direction"
            className={classes.content}
            validate={requiredValidation}
          />
        </Grid>
        <Grid item xs>
          <SelectInput
            source="partner_group"
            className={classes.content}
            defaultValue={null}
            choices={partnerGroupChoices}
          />
        </Grid>
        <Grid item xs>
          <TextInput source="partner_tag" className={classes.content} />
        </Grid>
        <Grid item xs>
          <TextInput source="spammer" className={classes.content} />
        </Grid>
        <Grid item xs>
          <BooleanInput
            source="is_shave_invalid_email"
            label="Shave invalid email"
            className={classes.content}
          />
        </Grid>
      </Grid>
    </div>
  );
};
