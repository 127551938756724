export const MODERATION_PROCESS = {
  is_documents_changed: true,
  is_other_details_changed: true,
  is_public_photos_changed: true,
  is_private_photos_changed: true,
  is_videos_changed: true,
  is_private_library_changed: true,
};

export const DEFAULT_MODERATION_RESPONSE = {
  retrieved_at: 0,

  documents: {},
  document_status: true,
  document_decline_reason: '',

  other_details: {},
  other_detail_status: true,
  other_detail_decline_reason: '',

  public_photos: [],
  private_photos: [],
  private_library: [],
  videos: [],

  statuses: MODERATION_PROCESS,
};

export const TABS = [
  'document_status',
  'other_detail_status',
  'public_photos',
  'private_photos',
  'private_library',
  'videos',
];

export const TAB_DECLINE_FIELD: { [key: string]: string } = {
  document_status: 'document_decline_reason',
  other_detail_status: 'other_detail_decline_reason',
};

export const GET_TUB_NUMBER_REG_EXP = /(?<=\d\/)\d+$/;
