import { SpendSegment } from 'types/enums/SpendSegment';

export const profileType = [
  { id: 1, name: 'user' },
  { id: 2, name: 'botclean' },
  { id: 3, name: 'botadult' },
  { id: 4, name: 'system' },
  { id: 5, name: 'tu_profile' },
  { id: 6, name: 'rutn' },
];

export const spendSegmentChoices = [
  { id: SpendSegment.NonBuyer, name: 'NonBuyer' },
  { id: SpendSegment.Minnow, name: 'Minnow' },
  { id: SpendSegment.Crab, name: 'Crab' },
  { id: SpendSegment.Dolphin, name: 'Dolphin' },
  { id: SpendSegment.Whale, name: 'Whale' },
];

export const logoutTimes = [
  { id: 1, label: '30 min', value: 30 },
  { id: 2, label: '1 hour', value: 60 },
  { id: 3, label: '3 hour', value: 180 },
  { id: 4, label: '8 hour', value: 480 },
  { id: 5, label: '24 hours ', value: 1440 },
];

enum Gender {
  Male = 1,
  Female = 2,
}

export const gender = [
  {
    id: 1,
    label: 'Male',
    value: Gender.Male,
  },
  {
    id: 2,
    label: 'Female',
    value: Gender.Female,
  },
];

export const MONTHS = [
  { id: 1, label: 'January', value: '01' },
  { id: 2, label: 'February', value: '02' },
  { id: 3, label: 'March', value: '03' },
  { id: 4, label: 'April', value: '04' },
  { id: 5, label: 'May', value: '05' },
  { id: 6, label: 'June', value: '06' },
  { id: 7, label: 'July', value: '07' },
  { id: 8, label: 'August', value: '08' },
  { id: 9, label: 'September', value: '09' },
  { id: 10, label: 'October', value: '10' },
  { id: 11, label: 'November', value: '11' },
  { id: 12, label: 'December', value: '12' },
];
