import React, { FC, useEffect, useState } from 'react';
import {
  Edit,
  BooleanField,
  ResourceComponentPropsWithId,
  useRefresh,
  FunctionField,
  useNotify,
} from 'react-admin';
import { Link } from 'react-router-dom';

import {
  Delete,
  RemoveCircle,
  ThumbUp,
  Edit as EditIcon,
  Done,
  Close,
  SwapHoriz,
} from '@material-ui/icons';
import { Box, Grid } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';

import { httpClient } from 'httpClient';
import { getUTCDateDifferenceInMinutes } from 'helper/date';

import ModerationStatusBadge from 'layout/ModerationStatusBadge';
import ErrorButton from 'layout/buttons/ErrorButton';
import SuccessButton from 'layout/buttons/SuccessButton';
import GreyButton from 'layout/buttons/GreyButton';
import ToggleActivateButton from 'layout/buttons/ToggleActivateButton';
import CustomSelect from 'layout/input/CustomSelect';
import BooleanFromValueField from 'layout/fields/BooleanFromValueField';

import { gender, logoutTimes } from '../field-choices';

import { UserLong } from 'model-types';
import { PageViews } from './submodules/pageViews';
import { CreditTransactions } from './submodules/creditTransactions';
import { ChangeAge } from './submodules/changeAge';
import { ProfileType } from 'types/enums/ProfileType';
import { formatISOToUtcDate } from 'helper/date';

const useStyles = makeStyles((theme) => ({
  booleanInput: {
    float: 'left',
    margin: '5px 0 0',
  },
  tableBody: {
    width: '100%',
  },
  tableLeftCell: {
    minWidth: '35%',
  },
}));

const ADD_CREDIT_DEFAULT = {
  amount: '0',
  user_id: 0,
  credits_type: 0,
};

interface RegularUserProps {
  record?: UserLong;
}

interface AddCredits {
  amount: string;
  user_id: number;
  credits_type: number;
}

const profileTypeChoices = [
  { id: ProfileType.User, label: 'user', value: ProfileType.User },
  { id: ProfileType.Rutn, label: 'rutn', value: ProfileType.Rutn },
];

interface TransactionsInfo {
  spend_segment: number;
  last_transaction: string;
  last_transaction_at: string;
  last_transaction_decline_reason: string;
}
interface PaymentTokenInfo {
  id: number;
  status: 'DECLINED' | 'ACTIVE';
  bin: string;
  has_charge_back: string;
  loading: boolean;
}

const UserTitle: FC<RegularUserProps> = ({ record }) =>
  record ? <span>Regular user #{record.id}</span> : null;

const EditForm: FC<RegularUserProps> = ({ record }) => {
  const refresh = useRefresh();
  const notify = useNotify();

  const [isPasswordEdit, setIsPasswordEdit] = useState<boolean>(false);
  const [isAddCredit, setIsAddCredit] = useState<boolean>(false);
  const [addCredit, setAddCredit] = useState<AddCredits>(ADD_CREDIT_DEFAULT);
  const [password, setPassword] = useState<string>('');
  const [toggleTestStatusLoader, setToggleTestStatusLoader] = useState(false);
  const [userGender, setUserGender] = useState(record?.gender);
  const [userType, setUserType] = useState(record?.profile_type);
  const [selectedLogoutTime, setSelectedLogoutTime] = useState(
    logoutTimes[0].value,
  );

  const [transactionInfo, setTransactionInfo] =
    useState<TransactionsInfo | null>(null);
  const [paymentTokenInfo, setPaymentTokenInfo] = useState<{
    tokens: PaymentTokenInfo[];
    hasChargeBack: boolean;
  }>({ tokens: [], hasChargeBack: false });

  const classes = useStyles();

  if (!record) {
    return null;
  }

  const ban = () => {
    if (record) {
      httpClient.get('/users/' + record.id + '/ban').then(() => refresh());
    }
  };

  const approve = () => {
    if (record) {
      httpClient.get('/users/' + record.id + '/approve').then(() => refresh());
    }
  };

  const remove = () => {
    if (record && confirm('Delete user #' + record.id + ' ?')) {
      httpClient.delete('/users/' + record.id).then(() => refresh());
    }
  };

  const popular = () => {
    if (
      record &&
      confirm('Swap popular status for user #' + record.id + ' ?')
    ) {
      httpClient
        .get('/users/' + record.id + '/is-popular')
        .then(() => refresh());
    }
  };

  const unsubscribe = () => {
    if (record) {
      httpClient.get('/unsubscribe/' + record.id).then(() => refresh());
    }
  };

  const unsubscribeCredit = () => {
    if (record) {
      httpClient.get('/unsubscribe-credit/' + record.id).then(() => refresh());
    }
  };

  const changePassword = () => {
    if (record) {
      httpClient
        .post('/users/' + record.id + '/change-password', {
          password: password,
        })
        .then(() => {
          setIsPasswordEdit(false);
          refresh();
        });
    }
  };

  const changeGender = () => {
    if (record) {
      httpClient
        .put(`/users/${record.id}`, {
          gender: Number(userGender),
        })
        .then(() => {
          refresh();
        })
        .catch((error) => {
          alert(error.message);
        });
    }
  };

  const changeUserType = () => {
    if (record) {
      httpClient
        .put(`/users/${record.id}`, {
          profile_type: Number(userType),
        })
        .then(() => {
          refresh();
        })
        .catch((error) => {
          alert(error.message);
        });
    }
  };

  const changeUserTestStatus = () => {
    if (record) {
      setToggleTestStatusLoader(true);

      const userType = record.trusted_user_id ? 'trusted-user' : 'users';
      const userId = record.trusted_user_id || record.id;

      httpClient
        .put(`/${userType}/${userId}/test`, {
          is_test: !record.is_test,
        })
        .then(() => {
          setToggleTestStatusLoader(false);
          refresh();
        });
    }
  };

  const toggleShowCreditAdd = () => {
    setIsAddCredit((prev) => !prev);
  };

  const handleCreditAmount = (e: any) => {
    setAddCredit((prev) => {
      return { ...prev, amount: e.target.value };
    });
  };

  const handleCreditType = (e: any) => {
    setAddCredit((prev) => {
      return { ...prev, credits_type: e.target.checked ? 1 : 0 };
    });
  };

  const handleAddCredit = () => {
    httpClient
      .post('/payment/add-credits', {
        ...addCredit,
        amount: Number(addCredit.amount) || 0,
      })
      .then(() => {
        toggleShowCreditAdd();
      });
  };

  const handleLogout = () => {
    if (
      confirm(`Log out user # ${record.id} on ${selectedLogoutTime} minutes?`)
    ) {
      httpClient
        .put(`/users/${record.id}/blocked`, {
          blocked_for: selectedLogoutTime,
        })
        .then(() => refresh());
    }
  };

  const handleTokenActivate = async (tokenId: number) => {
    setPaymentTokenInfo((prevInfo) => ({
      ...prevInfo,
      tokens: prevInfo.tokens.map((tokenItem) => {
        if (tokenItem.id === tokenId) return { ...tokenItem, loading: true };

        return tokenItem;
      }),
    }));

    const { status } = await httpClient.get(
      `/payment/activate-token/${tokenId}`,
    );

    if (status) {
      notify('The token has been activated!');

      setPaymentTokenInfo((prevInfo) => ({
        ...prevInfo,
        tokens: prevInfo.tokens.filter((tokenItem) => tokenItem.id !== tokenId),
      }));
    }
  };

  useEffect(() => {
    setAddCredit((prev) => {
      return { ...prev, user_id: record.id };
    });
  }, []);

  useEffect(() => {
    if (!record.id) return;

    httpClient
      .get<TransactionsInfo>(`/users/${record.id}/transaction-info`)
      .then((response) => {
        setTransactionInfo(response.data);
      });

    httpClient
      .get<{ data: PaymentTokenInfo[] }>(
        `/payment/get-declined-token-list/${record.id}`,
      )
      .then((response) => {
        const tokens = response.data.data;
        const hasChargeBack = !!tokens.find((token) => token.has_charge_back);

        setPaymentTokenInfo({
          tokens,
          hasChargeBack,
        });
      });
  }, [record.id]);

  return (
    <Grid container spacing={3} style={{ padding: '20px' }}>
      <Grid item xs={8}>
        <table cellPadding={7}>
          <tbody className={classes.tableBody}>
            <tr>
              <td>id</td>
              <td>#{record.id}</td>
            </tr>
            {record.trusted_user_external_id && (
              <tr>
                <td>Carthage TU id</td>
                <td style={{ color: 'rgb(59,130,246)' }}>
                  #{record.trusted_user_external_id}
                </td>
              </tr>
            )}
            {record.ulid_id && (
              <tr>
                <td>ulid</td>
                <td>{record.ulid_id}</td>
              </tr>
            )}
            {record.trusted_user_id && (
              <tr>
                <td>Trusted user</td>
                <td>
                  <GreyButton
                    component={Link}
                    size="small"
                    to={{ pathname: '/trusted-user/' + record.trusted_user_id }}
                  >
                    #{record.trusted_user_id}
                  </GreyButton>
                </td>
              </tr>
            )}
            {record.related_user_ids?.length > 0 && (
              <tr>
                <td>Related users</td>
                <td>
                  {record.related_user_ids.map((user_id) => (
                    <GreyButton
                      component={Link}
                      size="small"
                      style={{ marginRight: '10px' }}
                      to={{ pathname: '/users/' + user_id }}
                    >
                      #{user_id}
                    </GreyButton>
                  ))}
                </td>
              </tr>
            )}
            <tr>
              <td>email</td>
              <td>{record.email}</td>
            </tr>
            <tr>
              <td>site_id</td>
              <td>{record.site_id}</td>
            </tr>
            <tr>
              <td>age</td>
              <td>
                <ChangeAge userAge={record.age} userId={record.id} />
              </td>
            </tr>
            <tr>
              <td>name</td>
              <td>{record.name}</td>
            </tr>
            <tr>
              <td>password</td>
              <td>
                {!isPasswordEdit ? (
                  <>
                    ********
                    <GreyButton
                      id="change-password"
                      size="small"
                      style={{ marginLeft: '10px' }}
                      endIcon={<EditIcon />}
                      onClick={() => setIsPasswordEdit(true)}
                    >
                      Change
                    </GreyButton>
                  </>
                ) : (
                  <>
                    <input
                      type="text"
                      value={password}
                      onInput={(e) => setPassword(e.currentTarget.value)}
                    />
                    <SuccessButton
                      size="small"
                      endIcon={<Done />}
                      onClick={changePassword}
                    >
                      Change
                    </SuccessButton>
                    <GreyButton
                      size="small"
                      endIcon={<Close />}
                      onClick={() => setIsPasswordEdit(false)}
                    >
                      Cancel
                    </GreyButton>
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td>gender</td>
              <td>
                <CustomSelect
                  initialValue={userGender}
                  choices={gender}
                  onChange={setUserGender}
                />
                <SuccessButton size="small" onClick={changeGender}>
                  Save
                </SuccessButton>
              </td>
            </tr>
            <tr>
              <td>moderation_status</td>
              <td>
                <ModerationStatusBadge status={record.moderation_status} />
                <SuccessButton
                  size="small"
                  endIcon={<ThumbUp />}
                  onClick={approve}
                  disabled={record.moderation_status === 'ok'}
                >
                  Approve
                </SuccessButton>
                <ErrorButton
                  size="small"
                  endIcon={<RemoveCircle />}
                  onClick={ban}
                  disabled={record.moderation_status === 'ban'}
                >
                  Ban
                </ErrorButton>
              </td>
            </tr>
            <tr>
              <td>profile_type</td>

              {Number(userType) === ProfileType.TuProfile && <td>tu </td>}

              {!!userType &&
                [ProfileType.User, ProfileType.Rutn].includes(
                  Number(userType),
                ) && (
                  <td>
                    <CustomSelect
                      initialValue={userType}
                      choices={profileTypeChoices}
                      onChange={setUserType}
                    />
                    <SuccessButton size="small" onClick={changeUserType}>
                      Save
                    </SuccessButton>
                  </td>
                )}
            </tr>
            <tr>
              <td>is_deleted</td>
              <td>
                <BooleanField
                  record={record}
                  source="is_deleted"
                  className={classes.booleanInput}
                />
                <ErrorButton
                  size="small"
                  endIcon={<Delete />}
                  onClick={remove}
                  disabled={record.is_deleted}
                >
                  Delete
                </ErrorButton>
              </td>
            </tr>

            {!!paymentTokenInfo.tokens.length && (
              <tr>
                <td>tokens</td>
                <td>
                  <Box display="flex" flexWrap="wrap">
                    {paymentTokenInfo.tokens.map((tokenData) => (
                      <Box
                        key={tokenData.id}
                        display="flex"
                        alignItems="center"
                        marginBottom="3px"
                      >
                        bin: {tokenData.bin}
                        {!tokenData.has_charge_back && (
                          <SuccessButton
                            size="small"
                            style={{ marginLeft: '10px' }}
                            onClick={() => handleTokenActivate(tokenData.id)}
                            disabled={
                              tokenData.loading ||
                              paymentTokenInfo.hasChargeBack
                            }
                          >
                            Activate
                          </SuccessButton>
                        )}
                        {tokenData.has_charge_back && (
                          <ErrorButton size="small" disabled>
                            CB Inactive
                          </ErrorButton>
                        )}
                      </Box>
                    ))}
                  </Box>
                </td>
              </tr>
            )}

            <tr>
              <td>is_popular</td>
              <td>
                <BooleanField
                  record={record}
                  source="is_popular"
                  className={classes.booleanInput}
                />
                <ErrorButton
                  size="small"
                  endIcon={<SwapHoriz />}
                  style={{ marginLeft: '0' }}
                  onClick={popular}
                >
                  Swap popular
                </ErrorButton>
              </td>
            </tr>

            <tr>
              <td>spend_segment</td>
              <td>
                <FunctionField
                  style={{ textTransform: 'lowercase' }}
                  render={() => transactionInfo?.spend_segment || '-'}
                />
              </td>
            </tr>
            <tr>
              <td>last_transaction</td>
              <td>
                <FunctionField
                  render={() => transactionInfo?.last_transaction || '-'}
                />
              </td>
            </tr>
            <tr>
              <td>last_transaction_at</td>
              <td>
                <FunctionField
                  render={() =>
                    formatISOToUtcDate(transactionInfo?.last_transaction_at) ||
                    '-'
                  }
                />
              </td>
            </tr>
            <tr>
              <td>last_transaction_decline_reason</td>
              <td>
                <FunctionField
                  render={() =>
                    transactionInfo?.last_transaction_decline_reason || '-'
                  }
                />
              </td>
            </tr>

            <tr>
              <td>Logout user on</td>
              <td>
                <CustomSelect
                  choices={logoutTimes}
                  onChange={setSelectedLogoutTime}
                />
                <ErrorButton size="small" onClick={handleLogout}>
                  Log out
                </ErrorButton>
              </td>
              {record.blocked_for_at &&
                getUTCDateDifferenceInMinutes(record.blocked_for_at) > 0 && (
                  <td>
                    User blocked at{' '}
                    {getUTCDateDifferenceInMinutes(record.blocked_for_at)}{' '}
                    minutes
                  </td>
                )}
            </tr>
            <tr>
              <td>photo_count</td>
              <td>{record.photo_count}</td>
            </tr>
            <tr>
              <td>created_at</td>
              <td>{formatISOToUtcDate(record.created_at)}</td>
            </tr>
            <tr>
              <td>city</td>
              <td>{record.city}</td>
            </tr>
            <tr>
              <td>country</td>
              <td>{record.country}</td>
            </tr>
            <tr>
              <td>is_test</td>
              <td>
                <BooleanFromValueField source="is_test" />
                <ToggleActivateButton
                  isActivate={!record.is_test}
                  handleActivate={changeUserTestStatus}
                  handleDeactivate={changeUserTestStatus}
                  isLoader={toggleTestStatusLoader}
                />
              </td>
            </tr>
            <tr>
              <td>is_premium</td>
              <td>
                <BooleanField
                  record={record}
                  source="is_premium"
                  className={classes.booleanInput}
                />
                <ErrorButton
                  size="small"
                  endIcon={<RemoveCircle />}
                  onClick={unsubscribe}
                  disabled={!record.has_regular_subscription}
                >
                  Unsubscribe
                </ErrorButton>
              </td>
            </tr>
            <tr>
              <td>credits</td>
              <td>
                {record.credits}
                <ErrorButton
                  size="small"
                  endIcon={<RemoveCircle />}
                  onClick={unsubscribeCredit}
                  disabled={!record.has_credit_subscription}
                >
                  Stop delayed payment
                </ErrorButton>
              </td>
            </tr>
            <tr>
              <td>is_chb</td>
              <td>
                <BooleanField
                  record={record}
                  source="is_chb"
                  className={classes.booleanInput}
                />
              </td>
            </tr>
            <tr>
              <td>balance</td>
              <td>
                {!isAddCredit ? (
                  <>
                    0000
                    <SuccessButton
                      size="small"
                      style={{ marginLeft: '10px' }}
                      endIcon={<EditIcon />}
                      onClick={toggleShowCreditAdd}
                    >
                      Add credit
                    </SuccessButton>
                  </>
                ) : (
                  <>
                    <input
                      type="number"
                      value={addCredit.amount}
                      onInput={handleCreditAmount}
                    />
                    <FormControlLabel
                      control={
                        <Switch color="primary" onChange={handleCreditType} />
                      }
                      labelPlacement="top"
                      label="Is free"
                    />

                    <SuccessButton
                      size="small"
                      endIcon={<Done />}
                      onClick={handleAddCredit}
                    >
                      Add
                    </SuccessButton>
                    <GreyButton
                      size="small"
                      endIcon={<Close />}
                      onClick={toggleShowCreditAdd}
                    >
                      Cancel
                    </GreyButton>
                  </>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </Grid>

      <Grid item xs={4}>
        <img src={record.thumbnail} style={{ width: '100%' }} />
      </Grid>

      <Grid item xs={12}>
        <PageViews userId={record.id} />
      </Grid>

      <Grid item xs={12}>
        <CreditTransactions userId={record.id} />
      </Grid>
    </Grid>
  );
};

const RegularUserEdit: FC<ResourceComponentPropsWithId> = (props) => {
  return (
    <Edit title={<UserTitle />} {...props}>
      <EditForm />
    </Edit>
  );
};

export default RegularUserEdit;
