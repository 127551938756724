export const formatISOToUtcDate = (date?: string) => {
  if (!date) return null;

  if (date.length === 27 || date.length === 24 || date.length === 19) {
    return `${date.substring(0, 10)} ${date.substring(11, 19)}`;
  }

  return date;
};

export const getUTCDateDifferenceInMinutes = (UTCData: string): number => {
  const utcDate = new Date(UTCData);

  const newData = new Date();
  const currentUTCDate = new Date(
    newData.getTime() + newData.getTimezoneOffset() * 60000,
  );

  const milliseconds = utcDate.getTime() - currentUTCDate.getTime();
  const minutes = Math.floor(milliseconds / 60000);

  return minutes;
};
