import {
  PostbackEventType,
  PostbackSendType,
} from 'pages/partner/submodules/field-choices';
import { IceBreakersTypes } from 'types/enums/IceBreakersTypes';
import { ProfileType } from 'types/enums/ProfileType';
import { MediaType } from 'types/mediaType';
import { PostbackType } from 'types/PostbackType';

export interface Photo {
  id: number;
  is_main: boolean;
  big_url: string;
  profile_url: string;
  media_type: MediaType;
  moderation_status: number;
}

export interface Video {
  id: number;
  type: number;
  url: string;
  preview_url: string;
}

export interface City {
  id: number;
  name: string;
  region: string;
  country: string;
}
interface Country {
  id: number;
  name: string;
  geoname_id: number;
  iso2: string;
  iso3: string;
  parent_country_id: null | number;
}

export interface UserShort {
  id: number;
  user_id: number;
  name: string;
  age: number;
  gender: number;
  photo_count: number;
  country: Country;
  details: { about_me?: string; [key: string]: any };
}

export interface User {
  id: number;
  ulid_id: string;
  email: string;
  site_id: number;
  age: number;
  name: string;
  moderation_status: string;
  is_deleted: boolean;
  gender: string;
  profile_type: ProfileType;
  photo_count: number;
  thumbnail: string;
  created_at: string;
  city: string;
  host: string;
  country: string;
  country_name: string;
  is_premium: boolean;
  is_test: number;
  is_chb: boolean;
  credits: number;
  trusted_user_id: number | null;
  related_user_ids: number[] | [];
  trusted_user_external_id?: number;
}

export type UserLong = User & {
  has_regular_subscription: boolean;
  has_video_upsale_subscription: boolean;
  has_vip_subscription: boolean;
  has_credit_subscription: boolean;
  has_credit_upsale_subscription: boolean;
  has_video_promote_subscription: boolean;
  blocked_for_at: null | string;
};

export interface UserAdditionalInfo {
  id: number;
  host: string;
  country_name: string;
}

export interface WebpushTpl {
  id: number;
  type: number;
  title_text_source_id: number;
  body_text_source_id: number;
  link: string;
  is_active: boolean;
}

export interface Partner {
  id: number;
  name: string;
  region: string;
  country_code: string;
  platform: string;
  manager: string;
  partner_group: string;
  traffic_type: string;
  cost_model: string;
  direction: string;
  partner_tag: string;
  postback_type: PostbackType;
  spammer: string;
  network_id: number;
  is_shave_invalid_email: boolean;
  postback_event_configs: PostbackEventConfig[];
  subsource_groups: SubsourceGroup[];
  api_buying_config: ApiBuyingConfig;
  tds_path_configs: PartnerTdsPathConfig[];
  first_subsource: Subsource | null;
}

export interface Subsource {
  [subsource_id: number]: any;
  [name: string]: any;
  name2: string;
  name3: string;
  name4: string;
  partner_id: number;
  ts: string;
  level: number;
  subsource_group_id: number;
}

export interface PartnerNetwork {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface PostbackEventConfig {
  id: number;
  is_enabled: boolean;
  subsource_id: number | null;
  partner_id: number;
  event_type: PostbackEventType;
  send_type: PostbackSendType;
  enabled_percentage: number;
  percentage_apply_offset: number;
  payout: number;
  url_pattern: string;
  postback_event_config_timings: PostbackEventConfigTiming[];
  partner_config_emails: PartnerConfigEmail[];
  postback_event_config_ages: PostbackEventConfigAges[];
}

export interface SubsourceGroup {
  id: number;
  name: string;
  partner_id: number;
  event_type: number;
  enabled_percentage: number;
  percentage_apply_offset: number;
  payout: number;
  url_pattern: string;
  partner_config_emails: PartnerConfigEmail[];
  subsource_rules: SubsourceRule[];
  first_subsource: Subsource | null;
}

export interface SubsourceRule {
  id: number;
  field: string;
  operator: string;
  value: string;
  subsource_group_id: number;
}

export interface PostbackEventConfigTiming {
  id: number;
  from_hour: number;
  from_minute: number;
  to_hour: number;
  to_minute: number;
  enabled_percentage: number;
}

export interface PartnerConfigEmail {
  id: number;
  domain: string;
  enabled_percentage: number;
}

export interface PostbackEventConfigAges {
  id: number;
  min: number;
  max: number;
  shave: number;
  postback_event_config_id: number;
  created_at: string;
}
[];

export interface ApiBuyingConfig {
  api_key: string;
  is_reg_enabled: boolean;
  is_visit_enabled: boolean;
}
export type PartnerTdsPathConfig = {
  id: number;
  partner_id: boolean;
  subsource_id: number;
  tds_path_id: number;
  is_api_blocked: boolean;
};

export interface TransferGroup {
  id: number;
  name: string;
  qualification_reasons: number[];
  site_group_ids: number[];
}
export interface TransferBrand {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}
export interface TransferGeoSegment {
  id: number;
  name: string;
  countries: number[];
  created_at: string;
  updated_at: string;
}
export interface AffiliateNetwork {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}
export interface TransferOfferFilter {
  positive: number[];
  negative: number[];
  all: boolean | null;
}
export interface TransferOffer {
  id: number;
  is_active: boolean;
  campaign_id: string;
  weight: number;
  group_id: number;
  url: string;
  for_site_ids: number[];
  external_offer_id: string;
  is_remnant: boolean;
  premium_emails: boolean;
  brand_id: number;
  cost_model: string;
  hourly_cap: number | null;
  daily_cap: number | null;
  total_cap: number | null;
  cap_timezone: number;
  affiliate_network_id: number;
  platform: number[] | null;
  device_os: number[] | null;
  banner_placement: number[] | null;
  filters: {
    partner: TransferOfferFilter;
    country: TransferOfferFilter;
    [key: string]: TransferOfferFilter;
  };
  created_at: string;
  updated_at: string;
}

export interface SystemSetting {
  id: string;
  value: string;
}

export interface MarketingFlow {
  id: string;
  hash: string;
  segment: string;
  tds_path_id: number;
  description: string;
  created_at: string;
  updated_at: string;
}

export interface TdsPath {
  id: string;
  name: string;
  host: string;
  direction: string;
  landing_number: number;
  destination_path: string;
  created_at: string;
  updated_at: string;
}

export interface TextCategory {
  id: string;
  name: string;
  parent_id: number;
  created_at: string;
  updated_at: string;
  level: number;
}
export interface TextSource {
  id: string;
  key: string;
  value: string;
  category_id: number;
  created_at: string;
  updated_at: string;
}

export interface Creative {
  id: number;
  name: string;
  countries: [];
  placement: number;
  is_active: boolean;
  is_native: boolean;
  platforms: [];
  group_ids: number[];
  sites: number[];
  comment: string;
  type: string | null;
  creative: string;
  created_at: string;
  updated_at: string;
}

export interface Supply {
  id: number;
  name: string;
  site_ids: number[];
  coefficient: number;
  code_available: boolean;
  trusted_users_count: number;
}

export interface SupplyAgents {
  id: number;
  external_supply_agent_id: number;
  name: string;
  is_active: boolean;
  email: string;
  created_at: string;
  updated_ut: string;
  supply: {
    id: number;
    name: string;
    coefficient: number;
    site_ids: string[];
  };
}

export interface SupplyNotification {
  id: number;
  type: string;
  message: string;
  supplies: Supply[];
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

export interface ProfileSource {
  about_me: string;
  age: number;
  body_type: number;
  city: number;
  color_of_eyes: number;
  color_of_hair: number;
  count_children: number;
  date_of_birth: string;
  drinking: number;
  education: number;
  email: string;
  english_level: number;
  gender: number;
  goal: number[];
  height: number;
  hobbies: number[];
  lastname: string;
  marital_status: number;
  name: string;
  occupation: string;
  other_languages: number[];
  passport_number: string;
  people_aged_max: number;
  people_aged_min: number;
  personality_type: number;
  religion: number;
  smoking: number;
  traits: number[];
  weight: number;
}

export interface IceBreaker {
  id: number;
  type: IceBreakersTypes;
  message: string;
  media: Photo;
  is_active: number;
  is_deleted: number;
  trusted_user: {
    id: number;
    external_id: number;
    moderation_status: number;
    profile_source: ProfileSource;
  };
  childrens: {
    id: number;
    delay: number;
    message: string;
    sent_count: number;
    read_count: number;
  }[];
  moderation_status: IceBreakerModerationStatus;
  created_at: string;
  updated_at: string;
}

export enum IceBreakerModerationStatus {
  Moderation = 1,
  Approve = 2,
  Decline = 3,
}
