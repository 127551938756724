import { FC } from 'react';
import { Edit, TabbedForm, FormTab } from 'react-admin';

import { Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { DocumentSection } from 'component/trustedUser/DocumentSection';
import { OtherDetailsSection } from 'component/trustedUser/OtherDetailsSection';
import { ShortInfoBar } from 'component/trustedUser/ShortInfoBar';
import { TrustedUser } from 'types/trustedUser/trustedUserModeration';

import MediaList from './submodules/MediaList';
import CustomToolbar from './submodules/CustomToolbar';

interface UserProps {
  record?: TrustedUser;
}

const useStyles = makeStyles(() => ({
  trustedUserBox: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '90vh',
    maxWidth: '1400px',
    backgroundColor: '#fff',
  },
  tabbedForm: {
    width: '78%',
    maxWidth: '1000px',
    height: '100%',
  },
  tab: {
    minWidth: '16.66%',
    width: '16.66%',
  },
}));

const UserTitle: FC<UserProps> = ({ record }) =>
  record ? <span>Trusted user #{record.id}</span> : null;

const EditForm: FC<UserProps> = ({ record }) => {
  const classes = useStyles();

  if (!record) {
    return null;
  }

  return (
    <div className={classes.trustedUserBox}>
      <ShortInfoBar />

      <Card className={classes.tabbedForm}>
        <TabbedForm
          toolbar={<CustomToolbar record={record} />}
          submitOnEnter={false}
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'scroll',
          }}
        >
          <FormTab
            label="Documents"
            className={classes.tab}
            style={{
              color: record?.documents?.decline_reason ? '#d32f2f' : 'inherit',
              fontWeight: record?.documents?.decline_reason
                ? 'bold'
                : 'inherit',
            }}
          >
            <DocumentSection
              record={record}
              declineReason={record?.documents?.decline_reason}
            />
          </FormTab>
          <FormTab
            label="Other Details"
            className={classes.tab}
            style={{
              color: record?.other_details?.decline_reason
                ? '#d32f2f'
                : 'inherit',
              fontWeight: record?.other_details?.decline_reason
                ? 'bold'
                : 'inherit',
            }}
          >
            <OtherDetailsSection
              record={record}
              declineReason={record?.other_details?.decline_reason}
            />
          </FormTab>
          <FormTab label="Public Photos" className={classes.tab}>
            <MediaList media={record?.public_photos} />
          </FormTab>
          <FormTab label="Private Photos" className={classes.tab}>
            <MediaList media={record?.private_photos} />
          </FormTab>
          <FormTab label="Private library" className={classes.tab}>
            <MediaList media={record?.private_library} />
          </FormTab>
          <FormTab label="Videos" className={classes.tab}>
            <MediaList media={record?.videos} />
          </FormTab>
        </TabbedForm>
      </Card>
    </div>
  );
};

const TrustedUserEdit = (props: any) => {
  return (
    <Edit {...props} title={<UserTitle />} component="div">
      <EditForm />
    </Edit>
  );
};

export default TrustedUserEdit;
