import React from 'react';
import { FC } from 'react';
import {
  SimpleForm,
  SaveButton,
  Toolbar,
  FormDataConsumer,
  SelectInput,
} from 'react-admin';

import { Partner } from '../../model-types';
import ScrollButtons from '../../layout/ScrollButtons';

import { validationForm } from './submodules/validation';
import { MainConfigs } from './submodules/MainConfigs';
import { FacebookConfig } from './submodules/FacebookConfig';
import { GoogleAdsConfig } from './submodules/GoogleAdsConfig';
import { AffiliateConfig } from './submodules/AffiliateConfig';
import { PostbackType } from 'types/PostbackType';

const postbackTypeChoices = [
  { id: PostbackType.Affiliate, name: PostbackType[PostbackType.Affiliate] },
  { id: PostbackType.GoogleAds, name: PostbackType[PostbackType.GoogleAds] },
  { id: PostbackType.Facebook, name: PostbackType[PostbackType.Facebook] },
];

interface PartnerProps {
  record?: Partner;
}

const UserEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const PartnerForm: FC<PartnerProps> = ({ record, ...rest }) => {
  if (!record) {
    return null;
  }

  return (
    <>
      <SimpleForm
        {...rest}
        redirect="list"
        record={record}
        subscription={{}}
        validate={validationForm}
        toolbar={<UserEditToolbar />}
        mutators={{
          // @ts-ignore
          insert: ([name, index, value], state, { changeValue }) => {
            changeValue(state, name, (array: any) => {
              const copy = [...(array || [])];

              copy.splice(index, 0, value);

              return copy;
            });
          },
        }}
      >
        <MainConfigs />

        <SelectInput
          source="postback_type"
          defaultValue={postbackTypeChoices[0].id}
          choices={postbackTypeChoices}
        />

        <FormDataConsumer>
          {({ formData }: { formData: Partner }) => {
            if (formData.postback_type === PostbackType.Facebook) {
              return <FacebookConfig />;
            }

            if (formData.postback_type === PostbackType.GoogleAds) {
              return <GoogleAdsConfig />;
            }

            return <AffiliateConfig />;
          }}
        </FormDataConsumer>
      </SimpleForm>

      <ScrollButtons />
    </>
  );
};

export default PartnerForm;
