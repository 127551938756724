import React, { FC } from 'react';
import { TextField } from 'react-admin';
import { TrustedUser } from 'types/trustedUser/trustedUserModeration';
import Zoom from 'react-medium-image-zoom';

import { Grid, CardMedia, Typography, ImageListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import 'react-medium-image-zoom/dist/styles.css';
import { ErrorBadge } from 'component/common/ErrorBadge';

interface Props {
  declineReason: string;
  record: TrustedUser;
}

const useStyles = makeStyles((theme) => ({
  topRow: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridGap: '20px',
    alignItems: 'flex-start',
    width: '100%',
    marginBottom: '10px',
  },
  bottomRow: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    marginBottom: '10px',
  },
  img: {
    width: '100%',
    maxHeight: '300px',
    maxWidth: '300px',
    objectFit: 'contain',
    objectPosition: 'top 0 left 0',
  },
  span: {
    display: 'block',
    margin: '8px 0 0 0',
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
}));

export const DocumentSection: FC<Props> = ({ record, declineReason }) => {
  const classes = useStyles();

  if (!record) return null;

  return (
    <div style={{ height: '80%' }}>
      <div className={classes.topRow}>
        <Zoom>
          <CardMedia
            component="img"
            image={record.avatar}
            alt=""
            className={classes.img}
          />
        </Zoom>

        <Grid container>
          {!!declineReason && <ErrorBadge error={declineReason} />}

          <Grid container xs={12}>
            <Grid container direction="column" xs={5}>
              <span className={classes.span}>Date of birth</span>
              <TextField
                source="documents.date_of_birth"
                label="Date of birth"
              />
            </Grid>
            <Grid container direction="column" xs={5}>
              <span className={classes.span}>Email</span>
              <TextField source="documents.email" label="Email" />
            </Grid>
          </Grid>
          <Grid container xs={12}>
            <Grid container direction="column" xs={5}>
              <span className={classes.span}>Name</span>
              <TextField source="documents.name" label="Name" />{' '}
            </Grid>
            <Grid container direction="column" xs={5}>
              <span className={classes.span}>Last Name</span>
              <TextField source="documents.last_name" label="Last name" />
            </Grid>
          </Grid>
          <Grid container xs={12}>
            <Grid container direction="column" xs={5}>
              <span className={classes.span}>City</span>
              <TextField source="documents.city_id.name" label="City" />{' '}
            </Grid>
            <Grid container direction="column" xs={5}>
              <span className={classes.span}>Country</span>
              <TextField source="documents.country_id.name" label="Country" />
            </Grid>
          </Grid>
        </Grid>
      </div>

      <div className={classes.bottomRow}>
        <Grid container direction="column">
          <Typography
            variant="h6"
            color="textSecondary"
            style={{ textAlign: 'center' }}
          >
            Verification info
          </Typography>
          <Grid container direction="column">
            <span className={classes.span}>Passport number</span>
            <TextField source="documents.passport_number" />
          </Grid>
          {!!record?.documents?.passport_scan && (
            <Grid container direction="column">
              <span className={classes.span}>Passport scan</span>
              <Zoom>
                <CardMedia
                  component="img"
                  image={record.documents.passport_scan}
                  alt=""
                  className={classes.img}
                />
              </Zoom>
            </Grid>
          )}
          {!!record?.documents?.verification_documents && (
            <Grid container direction="column">
              <span className={classes.span}>Verification documents</span>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  listStyleType: 'none',
                }}
              >
                {record.documents.verification_documents.map(
                  (item: any, index: number) => (
                    <ImageListItem key={index}>
                      <Zoom>
                        <img
                          src={item.preview_url}
                          style={{
                            height: '180px',
                            objectFit: 'contain',
                            marginRight: '5px',
                          }}
                          loading="lazy"
                        />
                      </Zoom>
                    </ImageListItem>
                  ),
                )}
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};
