import React from 'react';
import { FormDataConsumer, useRecordContext } from 'react-admin';
import { makeStyles } from '@material-ui/core';

import { Partner } from 'model-types';

import { SubsourceGroupsConfigs } from './submodules/SubsourceGroupsConfigs';
import { PostbackEventConfigs } from './submodules/PostbackEventConfigs';
import { SubsourceConfigs } from './submodules/SubsourceConfigs';
import { APIConfigs } from './submodules/APIConfigs';

// TODO - refactor this file
// ! do not pass classes as classes props, use makeStyles instead
const useStyles = makeStyles(() => ({
  div: {
    width: '100%',
  },
  postbackConfigItem: {
    '&>ul>li>section>.ra-input': {
      width: '170px',
      display: 'inline-block',
      '& > div': {
        width: '155px',
      },
    },
    '&>ul>li>section>.ra-input:nth-child(1)': {
      width: '90px',
      '& > div': {
        width: '80px',
      },
    },
    '&>ul>li>section>.ra-input:nth-child(2)': {
      width: '140px',
      '& > div': {
        width: '130px',
      },
    },
    '&>ul>li>section>.ra-input:nth-child(3)': {
      width: '250px',
      '& > div': {
        width: '240px',
      },
    },
    '&>ul>li>section>.ra-input:nth-child(4)': {
      width: '180px',
      '& > div': {
        width: '170px',
      },
    },
    '&>ul>li>section>.ra-input:nth-child(5)': {
      width: '90px',
      '& > div': {
        width: '80px',
      },
    },
    '&>ul>li>section>.ra-input:nth-child(6)': {
      width: '110px',
      '& > div': {
        width: '100px',
      },
    },
    '&>ul>li>section>.ra-input:nth-child(7)': {
      width: '90px',
      '& > div': {
        width: '80px',
      },
    },
    '&>ul>li>section>.ra-input:nth-child(8)': {
      width: '800px',
      '& > div': {
        width: '800px',
      },
    },
    '&>ul>li>section>.ra-input:nth-child(9)': {
      width: '100%',
      '& > div': {
        width: '100%',
      },
    },
  },
  tdsPathConfigItem: {
    width: '100%',
    '&>ul>li>section>.ra-input': {
      width: '210px',
      display: 'inline-block',
      '& > div': {
        width: '200px',
        minWidth: '100px',
      },
    },
    '&>ul>li>section>.ra-input:nth-child(2)': {
      '& > div > div': {
        maxWidth: '100%',
      },
    },
    '&>ul>li>section>.ra-input:nth-child(3)': {
      verticalAlign: 'bottom',
      marginLeft: '30px',
    },
  },
  postbackConfigTimingItem: {
    width: '100%',
    '&>ul>li>section>.ra-input': {
      width: '110px',
      display: 'inline-block',
      '& > div': {
        width: '100px',
        minWidth: '100px',
      },
    },
    '&>ul>li>section>.ra-input:nth-child(2), &>ul>li>section>.ra-input:nth-child(4)':
      {
        width: '125px',
      },
  },
  subsourceGroup: {
    width: '100%',
    '&>ul>li>section>.ra-input': {
      display: 'inline-block',
      marginRight: '10px',
      '& > div': {
        width: 'auto',
      },
    },
    '&>ul>li>section>.ra-input:nth-child(2)': {
      width: '150px',
      '& > div': {
        width: '150px',
      },
    },
    '&>ul>li>section>.ra-input:nth-child(3)': {
      width: '180px',
      '& > div': {
        width: '180px',
      },
    },
    '&>ul>li>section>.ra-input:nth-child(4)': {
      width: '180px',
      '& > div': {
        width: '180px',
      },
    },
    '&>ul>li>section>.ra-input:nth-child(6)': {
      width: '120px',
      '& > div': {
        width: '120px',
      },
    },
    '&>ul>li>section>.ra-input:nth-child(7)': {
      width: '120px',
      '& > div': {
        width: '120px',
      },
    },
    '&>ul>li>section>.ra-input:nth-child(8)': {
      width: '100%',
      '& > div': {
        width: '100%',
      },
    },
    '&>ul>li>section>.ra-input:nth-last-child(9)': {
      width: '100%',
      marginTop: '10px',
    },
  },
  addVisitButton: {
    width: '100%',
    margin: '10px 0 20px',
  },
  deleteSubsourcePostbacksButton: {
    float: 'right',
  },
}));

export const AffiliateConfig: React.FC = React.memo(() => {
  const classes = useStyles();
  const record = useRecordContext();

  return (
    <>
      {record.id && record.first_subsource && (
        <SubsourceGroupsConfigs classes={classes} />
      )}

      <PostbackEventConfigs classes={classes} />

      <FormDataConsumer>
        {({ formData }: { formData: Partner }) => {
          return formData.traffic_type === 'api' ? <APIConfigs /> : null;
        }}
      </FormDataConsumer>

      <SubsourceConfigs className={classes.tdsPathConfigItem} />
    </>
  );
});
