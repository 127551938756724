import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import {
  Edit,
  ResourceComponentPropsWithId,
  SimpleForm,
  TextInput,
  useRefresh,
  AutocompleteArrayInput,
  NumberInput,
  BooleanInput,
  ReferenceArrayInput,
  SelectArrayInput,
} from 'react-admin';
import { SystemSetting } from 'model-types';
import { getCountryNameChoices } from 'countries';
import { PidvalApi, SiteIdChoices } from 'api/PidvalApi';

interface SystemSettingsProps {
  record?: SystemSetting;
}

const SystemSettingsTitle: FC<SystemSettingsProps> = ({ record }) =>
  record ? <span>System setting [{record.id}]</span> : null;

const SystemSettingsEdit: FC<ResourceComponentPropsWithId> = (props) => {
  const refresh = useRefresh();

  const [siteIdChoices, setSiteIdChoices] = useState<SiteIdChoices[]>([]);

  useEffect(() => {
    const setSiteId = async () => {
      try {
        const siteInfo = await PidvalApi.fetchMirrorShortInfo();
        setSiteIdChoices(siteInfo);
      } catch (error) {
        console.error(error);
      }
    };

    if (props?.id === 'detect_profile_type_site_ids') setSiteId();
  }, [props?.id]);

  return (
    <Edit title={<SystemSettingsTitle />} {...props}>
      <SimpleForm redirect="list">
        <TextInput disabled source="id" label="Key" />

        {props.id === 'api_buying_allow_countries' && (
          <AutocompleteArrayInput
            source="value"
            choices={getCountryNameChoices()}
            style={{ width: '100%' }}
          />
        )}

        {props.id === 'epc_min_clicks' && (
          <NumberInput source="value" label="Value" />
        )}

        {props.id === 'detect_profile_type_enabled' && (
          <BooleanInput source="value" label="Value" />
        )}

        {props.id === 'detect_profile_type_network_ids' && (
          <ReferenceArrayInput
            label="Network"
            source="value"
            optionValue="network_id"
            reference="partners-networks"
            allowEmpty={true}
          >
            <AutocompleteArrayInput optionText="name" />
          </ReferenceArrayInput>
        )}

        {props.id === 'detect_profile_type_site_ids' && (
          <AutocompleteArrayInput
            label="Site ids"
            source="value"
            choices={siteIdChoices}
          />
        )}
      </SimpleForm>
    </Edit>
  );
};

export default SystemSettingsEdit;
