import React, { useState } from 'react';
import {
  Datagrid,
  DateField,
  List,
  NumberField,
  ReferenceField,
  AutocompleteInput,
  TextField,
  Filter,
  NumberInput,
  ArrayField,
  SingleFieldList,
  SelectInput,
  BooleanField,
  NullableBooleanInput,
  ReferenceInput,
  TextInput,
  ResourceComponentProps,
  useRedirect,
  FunctionField,
} from 'react-admin';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import ConfigListView from './ConfigListView';
import { trafficTypeChoices } from './submodules/field-choices';
import { costModelChoices } from 'consts';
import { httpClient } from 'httpClient';
import { PostbackType } from 'types/PostbackType';

const DuplicateBtn = ({ record }: any) => {
  const redirect = useRedirect();

  const [isLoading, setIsLoading] = useState(false);

  const handleClickDuplicate = (e: any) => {
    e.stopPropagation();

    setIsLoading(true);

    httpClient.get('/partners/duplicate/' + record.id).then((response: any) => {
      setIsLoading(false);

      redirect('/partners/' + response.data.id);
    });
  };

  return (
    <IconButton
      id="duplicate-button"
      onClick={handleClickDuplicate}
      disabled={isLoading}
    >
      {isLoading && <CircularProgress color="inherit" size="20px" />}
      {!isLoading && <FileCopyIcon fontSize="small" />}
    </IconButton>
  );
};

const PartnerFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput source="id" alwaysOn />
    <TextInput source="name" alwaysOn />
    <SelectInput source="traffic_type" choices={trafficTypeChoices} alwaysOn />
    <SelectInput source="cost_model" choices={costModelChoices} alwaysOn />
    <NullableBooleanInput
      source="is_shave_invalid_email"
      label="Shave invalid email"
      alwaysOn
    />
    <ReferenceInput
      label="Network"
      source="network_id"
      reference="partners-networks"
      allowEmpty={true}
      filterToQuery={(searchText: any) => ({ name: '%' + searchText + '%' })}
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <TextInput source="country_code" alwaysOn />
  </Filter>
);

const PartnerList = (props: ResourceComponentProps) => {
  return (
    <List
      {...props}
      filters={<PartnerFilter />}
      perPage={100}
      sort={{ field: 'updated_at', order: 'DESC' }}
    >
      <Datagrid optimized rowClick="edit">
        <NumberField source="id" />
        <DuplicateBtn />
        <TextField source="name" />
        <ArrayField source="postback_event_configs" label="Configs">
          <SingleFieldList>
            <ConfigListView />
          </SingleFieldList>
        </ArrayField>
        <TextField source="region" />
        <TextField source="country_code" />
        <TextField source="platform" />
        <TextField source="manager" />
        <TextField source="partner_group" />
        <TextField source="traffic_type" />
        <FunctionField
          label="Postback type"
          render={(record?: Record<string, any>) =>
            PostbackType[record?.postback_type || 1]
          }
        />
        <TextField source="cost_model" />
        <TextField source="direction" />
        <ReferenceField
          label="Network"
          source="network_id"
          reference="partners-networks"
        >
          <TextField source="name" />
        </ReferenceField>
        <BooleanField
          source="is_shave_invalid_email"
          label="Shave invalid email"
        />
        <DateField source="updated_at" locales="ru-RU" showTime />
      </Datagrid>
    </List>
  );
};

export default PartnerList;
