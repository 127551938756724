import React from 'react';
import {
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
} from 'react-admin';

import {
  hourChoices,
  minuteChoices,
} from 'pages/partner/submodules/field-choices';

interface Props {
  source: string;
  className?: string;
}

export const TimingConfigs: React.FC<Props> = React.memo(
  ({ source, className }) => {
    return (
      <>
        <p>Timing configs:</p>
        <ArrayInput
          source={source}
          label=""
          className={className}
          subscription={{}}
        >
          <SimpleFormIterator>
            <SelectInput
              source="from_hour"
              defaultValue={0}
              label="From Hour"
              choices={hourChoices}
            />
            <SelectInput
              source="from_minute"
              defaultValue={0}
              label="From Minute"
              choices={minuteChoices}
            />
            <SelectInput
              source="to_hour"
              defaultValue={1}
              label="To Hour"
              choices={hourChoices}
            />
            <SelectInput
              source="to_minute"
              defaultValue={0}
              label="To Minute"
              choices={minuteChoices}
            />
            <NumberInput
              source="enabled_percentage"
              defaultValue={100}
              min={0}
              max={100}
              step={1}
              label="% Enabled"
            />
          </SimpleFormIterator>
        </ArrayInput>
      </>
    );
  },
);
