import React from 'react';
import {
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
} from 'react-admin';

import { postbackEmailDomainChoices } from 'pages/partner/submodules/field-choices';

interface Props {
  classNames?: {
    postbackConfigTimingItem: string;
  };
  source: string;
  enabledPercentage?: number;
}

export const EmailConfigs: React.FC<Props> = React.memo(
  ({ classNames, source, enabledPercentage }) => {
    return (
      <>
        <p style={{ marginTop: '15px' }}>Email configs:</p>
        <ArrayInput
          source={source}
          label=""
          className={classNames?.postbackConfigTimingItem}
          subscription={{}}
        >
          <SimpleFormIterator>
            <SelectInput
              source="domain"
              defaultValue={null}
              label="Domain"
              choices={postbackEmailDomainChoices}
            />
            <NumberInput
              source="enabled_percentage"
              defaultValue={enabledPercentage}
              min={0}
              max={100}
              step={1}
              label="% Enabled"
            />
          </SimpleFormIterator>
        </ArrayInput>
      </>
    );
  },
);
