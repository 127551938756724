import { FC, useCallback, useEffect, useState } from 'react';
import { Edit, TabbedForm, FormTab } from 'react-admin';
import cx from 'classnames';

import { Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import { httpClient } from 'httpClient';

import { DocumentSection } from 'component/trustedUser/DocumentSection';
import { OtherDetailsSection } from 'component/trustedUser/OtherDetailsSection';
import { ShortInfoBar } from 'component/trustedUser/ShortInfoBar';
import MediaList from './submodules/MediaList';
import CustomToolbar from './submodules/CustomToolbar';

import {
  TrustedUser,
  TrustedUserMedia,
  TrustedUserModerationData,
} from 'types/trustedUser/trustedUserModeration';
import { DEFAULT_MODERATION_RESPONSE } from './constants';

interface UserProps {
  record?: TrustedUser;
}

const useStyles = makeStyles((theme) => ({
  trustedUserBox: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '90vh',
    maxWidth: '1400px',
    backgroundColor: '#fff',
  },
  tabbedForm: {
    width: '78%',
    maxWidth: '1000px',
    height: '100%',
  },
  tab: {
    position: 'relative',
    minWidth: '16.66%',
    width: '16.66%',
    color: 'inherit',
    fontWeight: 'inherit',
  },
  tabDeclined: {
    color: '#d32f2f',
    fontWeight: 'bold',
  },
  tabDataChanged: {
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '15px',
      right: '5px',
      width: '7px',
      height: '7px',
      borderRadius: '50%',
      backgroundColor: '#d32f2f',
    },
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    margin: '150px 0',
  },
}));

const applyMediaDraft = (
  media: TrustedUserMedia[],
  draftMedia: TrustedUserMedia[],
) => {
  if (!draftMedia?.length)
    return {
      data: media,
      isChanged: false,
    };

  return {
    isChanged: media.length !== draftMedia.length,
    data: media.map((mediaItem) => {
      const draftItem = draftMedia.find((draft) => draft.id === mediaItem.id);

      if (draftItem) return { ...mediaItem, ...draftItem };

      return mediaItem;
    }),
  };
};

const UserTitle: FC<UserProps> = ({ record }) =>
  record ? <span>Trusted user #{record.id}</span> : null;

// TODO use api from PidvalApi instead of httpClient
// TODO fix console errors

const EditForm: FC<UserProps> = ({ record }) => {
  const [isDraftSaving, setIsDraftSaving] = useState(false);
  const [moderationData, setModerationData] =
    useState<TrustedUserModerationData>(DEFAULT_MODERATION_RESPONSE);

  const classes = useStyles();

  const setPublicPhotos = useCallback(
    (setStateAction: (prevMedia: TrustedUserMedia[]) => TrustedUserMedia[]) => {
      setModerationData((prevModerationData) => ({
        ...prevModerationData,
        public_photos: setStateAction(prevModerationData.public_photos),
        statuses: {
          ...prevModerationData.statuses,
          is_public_photos_changed: false,
        },
      }));
    },
    [],
  );

  const setPrivatePhotos = useCallback(
    (setStateAction: (prevMedia: TrustedUserMedia[]) => TrustedUserMedia[]) => {
      setModerationData((prevModerationData) => ({
        ...prevModerationData,
        private_photos: setStateAction(prevModerationData.private_photos),
        statuses: {
          ...prevModerationData.statuses,
          is_private_photos_changed: false,
        },
      }));
    },
    [],
  );

  const setPrivateLibrary = useCallback(
    (setStateAction: (prevMedia: TrustedUserMedia[]) => TrustedUserMedia[]) => {
      setModerationData((prevModerationData) => ({
        ...prevModerationData,
        private_library: setStateAction(prevModerationData.private_library),
        statuses: {
          ...prevModerationData.statuses,
          is_private_library_changed: false,
        },
      }));
    },
    [],
  );

  const setVideos = useCallback(
    (setStateAction: (prevMedia: TrustedUserMedia[]) => TrustedUserMedia[]) => {
      setModerationData((prevModerationData) => ({
        ...prevModerationData,
        videos: setStateAction(prevModerationData.videos),
        statuses: {
          ...prevModerationData.statuses,
          is_videos_changed: false,
        },
      }));
    },
    [],
  );

  const loadModerationDraft = useCallback(async (userId: number) => {
    const data = await httpClient.get<TrustedUserModerationData>(
      `/trusted-user/moderation/${userId}/draft`,
    );

    return { draft: data.data };
  }, []);

  const saveDraft = useCallback(async () => {
    if (!record?.id || !moderationData?.retrieved_at) return;

    setIsDraftSaving(true);

    try {
      await httpClient.post(
        `/trusted-user/moderation/${record.id}/draft`,
        moderationData,
      );
    } finally {
      setIsDraftSaving(false);
    }
  }, [record?.id, moderationData]);

  useEffect(() => {
    if (
      !record?.id ||
      !record?.public_photos ||
      !record?.private_photos ||
      !record?.private_library
    )
      return;

    const retrieved_at = Math.floor(new Date().getTime() / 1000);

    loadModerationDraft(record.id)
      .then(({ draft }) => {
        setModerationData((initData) => {
          const documentDeclineReasonDraft =
            draft.document_decline_reason || initData.document_decline_reason;

          const otherDetailDeclineReasonDraft =
            draft.document_decline_reason || initData.document_decline_reason;

          const publicPhotosDraft = applyMediaDraft(
            record.public_photos,
            draft.public_photos,
          );

          const privatePhotosDraft = applyMediaDraft(
            record.private_photos,
            draft.private_photos,
          );

          const privateLibraryDraft = applyMediaDraft(
            record.private_library,
            draft.private_library,
          );

          const videosDraft = applyMediaDraft(record.videos, draft.videos);

          const isDocumentsChanged =
            JSON.stringify({ ...record.documents, avatar: record.avatar }) !==
            JSON.stringify(draft.documents || {});

          const isOtherDetailsChanged =
            JSON.stringify(record.other_details) !==
            JSON.stringify(draft.other_details || {});

          return {
            document_decline_reason: documentDeclineReasonDraft,
            document_status: !documentDeclineReasonDraft,
            documents: { ...record.documents, avatar: record.avatar },

            other_detail_decline_reason: otherDetailDeclineReasonDraft,
            other_detail_status: !otherDetailDeclineReasonDraft,
            other_details: record.other_details,

            public_photos: publicPhotosDraft.data,
            private_photos: privatePhotosDraft.data,
            private_library: privateLibraryDraft.data,
            videos: videosDraft.data,

            statuses: {
              is_documents_changed:
                isDocumentsChanged && !!initData.retrieved_at,
              is_other_details_changed:
                isOtherDetailsChanged && !!initData.retrieved_at,

              is_private_library_changed:
                privateLibraryDraft.isChanged && !!initData.retrieved_at,
              is_private_photos_changed:
                privatePhotosDraft.isChanged && !!initData.retrieved_at,
              is_public_photos_changed:
                publicPhotosDraft.isChanged && !!initData.retrieved_at,
              is_videos_changed:
                videosDraft.isChanged && !!initData.retrieved_at,
            },

            retrieved_at,
          };
        });
      })
      .catch(() => {
        setModerationData((initData) => ({
          ...initData,
          public_photos: record.public_photos,
          private_photos: record.private_photos,
          private_library: record.private_library,
          videos: record.videos,
          retrieved_at,
        }));
      });
  }, [loadModerationDraft, record]);

  // ? save draft with debounce
  useEffect(() => {
    const handler = setTimeout(saveDraft, 7000);

    return () => clearTimeout(handler);
  }, [saveDraft]);

  if (!record || !moderationData.retrieved_at)
    return (
      <div className={classes.loader}>
        <CircularProgress color="secondary" size="30px" />
      </div>
    );

  return (
    <div className={classes.trustedUserBox}>
      <ShortInfoBar isModeration />

      <Card className={classes.tabbedForm}>
        <TabbedForm
          toolbar={
            <CustomToolbar
              record={record}
              moderationData={moderationData}
              setModerationData={setModerationData}
              saveDraft={saveDraft}
              isDraftSaving={isDraftSaving}
            />
          }
          submitOnEnter={false}
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'scroll',
          }}
        >
          <FormTab
            label="Documents"
            className={cx(classes.tab, {
              [classes.tabDeclined]: !!moderationData.document_decline_reason,
              [classes.tabDataChanged]:
                moderationData.statuses.is_documents_changed,
            })}
          >
            <DocumentSection
              record={record}
              declineReason={
                moderationData.document_decline_reason ||
                record.documents?.decline_reason
              }
            />
          </FormTab>

          <FormTab
            label="Other Details"
            className={cx(classes.tab, {
              [classes.tabDeclined]:
                !!moderationData.other_detail_decline_reason,
              [classes.tabDataChanged]:
                moderationData.statuses.is_other_details_changed,
            })}
          >
            <OtherDetailsSection
              record={record}
              declineReason={
                moderationData.other_detail_decline_reason ||
                record.other_details?.decline_reason
              }
            />
          </FormTab>

          <FormTab
            label="Public Photos"
            className={cx(classes.tab, {
              [classes.tabDataChanged]:
                moderationData.statuses.is_public_photos_changed,
            })}
          >
            <MediaList
              media={moderationData.public_photos}
              setMedia={setPublicPhotos}
              tabNumber={2}
            />
          </FormTab>

          <FormTab
            label="Private Photos"
            className={cx(classes.tab, {
              [classes.tabDataChanged]:
                moderationData.statuses.is_private_photos_changed,
            })}
          >
            <MediaList
              media={moderationData.private_photos}
              setMedia={setPrivatePhotos}
              tabNumber={3}
            />
          </FormTab>

          <FormTab
            label="Private library"
            className={cx(classes.tab, {
              [classes.tabDataChanged]:
                moderationData.statuses.is_private_library_changed,
            })}
          >
            <MediaList
              media={moderationData.private_library}
              setMedia={setPrivateLibrary}
              tabNumber={4}
            />
          </FormTab>

          <FormTab
            label="Videos"
            className={cx(classes.tab, {
              [classes.tabDataChanged]:
                moderationData.statuses.is_videos_changed,
            })}
          >
            <MediaList
              media={moderationData.videos}
              setMedia={setVideos}
              tabNumber={5}
            />
          </FormTab>
        </TabbedForm>
      </Card>
    </div>
  );
};

const TrustedUserModeration = (props: any) => {
  return (
    <Edit {...props} title={<UserTitle />} component="div">
      <EditForm />
    </Edit>
  );
};

export default TrustedUserModeration;
