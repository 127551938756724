import React, { FC } from 'react';
import { TextField } from 'react-admin';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import OtherDetailTextField from './OtherDetailTextField';

import { TrustedUser } from 'types/trustedUser/trustedUserModeration';
import { ErrorBadge } from 'component/common/ErrorBadge';

interface Props {
  declineReason: string;
  record: TrustedUser;
}

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    marginBottom: '10px',
  },
  span: {
    display: 'block',
    margin: '8px 0 0 0',
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
}));

export const OtherDetailsSection: FC<Props> = ({ record, declineReason }) => {
  const classes = useStyles();

  if (!record.options) return null;

  return (
    <div className={classes.row}>
      <Grid container xs={12}>
        {!!declineReason && <ErrorBadge error={declineReason} />}

        <Grid xs={6}>
          <span className={classes.span}>Gender</span>
          {!!record.other_details.gender && (
            <span>{record.other_details.gender === 1 ? 'Male' : 'Female'}</span>
          )}
          <OtherDetailTextField
            label="Body type"
            field="body_type"
            record={record}
          />
        </Grid>
        <Grid xs={6}>
          <OtherDetailTextField label="Height" field="height" record={record} />
          <OtherDetailTextField label="Weight" field="weight" record={record} />
        </Grid>
        <Grid xs={6}>
          <OtherDetailTextField
            label="Color of eyes"
            field="color_of_eyes"
            record={record}
          />
          <OtherDetailTextField
            label="Color of hair"
            field="color_of_hair"
            record={record}
          />
        </Grid>
        <Grid xs={6}>
          <OtherDetailTextField
            label="Marital status"
            field="marital_status"
            record={record}
          />
          <span className={classes.span}>Count of children</span>
          <TextField source="other_details.count_children" />
        </Grid>
        <Grid xs={6}>
          <OtherDetailTextField
            label="Education"
            field="education"
            record={record}
          />
          <OtherDetailTextField
            label="English level"
            field="english_level"
            record={record}
          />
        </Grid>
        <Grid xs={6}>
          <span className={classes.span}>Other languages</span>
          {record.other_details.other_languages && (
            <span>
              {record.other_details.other_languages.map((hobby, index) =>
                index === record.other_details.other_languages.length - 1
                  ? record.options.other_languages.options[hobby].label
                  : record.options.other_languages.options[hobby].label + ', ',
              )}
            </span>
          )}
          <OtherDetailTextField
            label="Religion"
            field="religion"
            record={record}
          />
        </Grid>
        <Grid xs={6}>
          <OtherDetailTextField
            label="Smoking"
            field="smoking"
            record={record}
          />
          <OtherDetailTextField
            label="Drinking"
            field="drinking"
            record={record}
          />
        </Grid>
        <Grid xs={6}>
          <span className={classes.span}>Traits</span>
          {record.other_details.traits && (
            <span>
              {record.other_details.traits.map((hobby, index) =>
                index === record.other_details.traits.length - 1
                  ? record.options.traits.options[hobby].label
                  : record.options.traits.options[hobby].label + ', ',
              )}
            </span>
          )}
          <span className={classes.span}>Hobbies</span>
          {record.other_details.hobbies && (
            <span>
              {record.other_details.hobbies.map((hobby, index) =>
                index === record.other_details.hobbies.length - 1
                  ? record.options.hobbies.options[hobby].label
                  : record.options.hobbies.options[hobby].label + ', ',
              )}
            </span>
          )}
        </Grid>
        <Grid xs={6}>
          <OtherDetailTextField
            label="Personality type"
            field="personality_type"
            record={record}
          />
          <span className={classes.span}>Goals</span>
          {record.other_details.goal && (
            <span>
              {record.other_details.goal.map((hobby, index) =>
                index === record.other_details.goal.length - 1
                  ? record.options.goal.options[hobby].label
                  : record.options.goal.options[hobby].label + ', ',
              )}
            </span>
          )}
        </Grid>
        <Grid xs={6}>
          <span className={classes.span}>People aged min</span>
          <TextField source="other_details.people_aged_min" />
          <span className={classes.span}>People aged max</span>
          <TextField source="other_details.people_aged_max" />
        </Grid>
        <Grid xs={12}>
          <span className={classes.span}>Occupation</span>
          <TextField source="other_details.occupation" />
          <span className={classes.span}>Description</span>
          <TextField source="other_details.about_me" />
        </Grid>
      </Grid>
    </div>
  );
};
